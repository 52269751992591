import Cookies from 'js-cookie'

const TokenKey = 'wtranst_member_token'
const RememberMeTokenKey = 'wtranst_user_rememberme_token'
let HeadCacheKey = 'head_cache'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRemToken() {
  const remToken = RememberMeTokenKey
  return Cookies.get(remToken)
}

export function setRemToken(token) {
  const remToken = RememberMeTokenKey
  return Cookies.set(remToken, token)
}

export function removeRemToken() {
  const remToken = RememberMeTokenKey
  return Cookies.remove(remToken)
}

export function getHeadCache () {
  let key = HeadCacheKey
  let cookie_val = Cookies.get(key)

  if (cookie_val) {
    return JSON.parse(cookie_val)
  } else {
    return
  }
}

export function setHeadCache (cacheObj) {
  let key = HeadCacheKey
  debugger;
  let cookie_val = JSON.stringify(cacheObj)
  return Cookies.set(key, cookie_val)
}

export function removeHeadCache (id) {
  let key = HeadCacheKey + '_' + id
  return Cookies.remove(key)
}
